<template>
    <v-container class="fill-height" fluid>
        <v-row justify-center>
            <!-- <h1 class="text-center text-md text-black">{{params}}</h1> -->
            <v-col cols="12">
                <scaling-squares-spinner
                v-if="loading"
                class="mx-auto"
                :animation-duration="1250"
                :size="65"
                color="#ff1d5e"
                />
            </v-col>

        <v-col cols="11" class="mx-auto my-5" >
            <v-card-subtitle v-if="response !== null" class="text-center text-green-700">{{response}}</v-card-subtitle>
            <v-card-subtitle v-if="response !== null" class="text-center text-green-700">Please close this tab and login to your account</v-card-subtitle>
        </v-col>

        <v-col cols="11" class="mx-auto my-5" >
            <v-card-subtitle v-if="error !== null" class="text-center text-red-700">{{error}}</v-card-subtitle>
        </v-col>

        </v-row>
    </v-container>
</template>

<script>
import { ScalingSquaresSpinner  } from 'epic-spinners'
// import axios from 'axios'

export default {
    name: 'UserVerification',

    components: {ScalingSquaresSpinner},

    data(){
        return {
            loading: false,
            response: null,
            showResponse: false,
            error: null
        }
    },

    computed: {
        token() {
            return this.$route.query.token
        },
        user() {
            return this.$session.get('user')
        }
    },
    mounted() {
        this.verifyUser()
    },

    methods: {
        async verifyUser() {
            // console.log('verifyUser');
            // try {
            //     this.loading = true

            //     const res = await axios.post(process.env.VUE_APP_API_URL +'/user/verify-email', {token: this.token}, {
            //         headers: {
            //             "Content-Type": "application/json"
            //         }
            //     })

            //     //update user session ie add email_verified_at
            //     let user = res.data.users[0]
            //     this.$session.remove('user')
            //     this.$session.set('user', user)
            //     // replace user details in session with new user details

            //     this.loading = false
            //     this.response = res.data.message
            //     this.showResponse = true

            //     // this.$router.go({path: '/'})
                
            // } catch (error) {
            //     this.loading = false
            //     this.error = error.response.data.message
            // }
        }
    }

}
</script>

<style>

</style>